<template>
  <div>
    <div>
      <b-card no-body class="">
        <b-card-text>
          <div class="d-flex justify-content-between p-2">
            <div class="c_ol-md-6">
              <h2>{{ currentEnterprise.name }}</h2>
            </div>
          </div>
        </b-card-text>

        <b-card-text class="px-2 pb-2">
          <b-form @submit.prevent="updateSettings" @reset="">
            <b-row>
              <b-col>
                <b-form-group>
                  <ky-input
                    placeholder="Nombre maximal de collaborateurs"
                    type="number"
                    v-model="settings_credentials.max_collaborators"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <ky-input
                    placeholder="Nombre maximal de clients"
                    type="number"
                    v-model="settings_credentials.max_customers"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <ky-input
                    placeholder="Nombre maximal de projets"
                    type="number"
                    v-model="settings_credentials.max_projects"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              :disabled="isEditing"
              type="submit"
              variant="outline-primary"
              >Sauvegarder
              <b-spinner v-if="isEditing" type="grow" small></b-spinner
            ></b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BForm,
  BButton,
  BRow,
  BCol,
  BToast,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BToast,
    KyInput,
    ToastificationContent,
  },

  data() {
    return {
      isEditing: false,
      id: parseInt(this.$route.params.id),
      currentEnterprise: {},
      settings_credentials: {
        id: 0,
        max_collaborators: 0,
        max_customers: 0,
        max_projects: 0,
      },
    };
  },
  computed: {
    ...mapState("enterprise", ["enterprise"]),
  },

  methods: {
    ...mapActions("enterprise", ["getEnterpriseById", "editSettings"]),

    getEnterprise() {},

    updateSettings() {
      this.isEditing = true;
      this.settings_credentials.id = this.currentEnterprise.id;

      this.editSettings(this.$formData(this.settings_credentials))
        .then(() => {
          this.isEditing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Paramètres mis à jour avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.isEditing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    clearForm() {
      settings_credentials = {
        id: null,
        max_collaborators: 0,
        max_customers: 0,
        max_tasks: null,
        max_projects: 0,
      };
    },
  },

  mounted() {
    this.getEnterpriseById(this.id).then((response) => {
      this.currentEnterprise = response;
      this.settings_credentials.max_collaborators =
        this.currentEnterprise.setting.max_collaborators;
      this.settings_credentials.max_customers =
        this.currentEnterprise.setting.max_customers;
      this.settings_credentials.max_projects =
        this.currentEnterprise.setting.max_projects;
    });
  },
};
</script>
